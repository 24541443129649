export default {
  loginName: "アカウント",
  password: "パスワード",
  register: "アカウントを作成",
  confirmPassword: "パスワードの確認",
  accountName: "お名前",
  tel: "電話",
  mail: "e-mail",
  address: "住所",
  captcha: "キャプチャ",
  inputRequire: "必須項目を入力してください",
  passwordNotEquals: "同じパスワードを入力してください",
  concatRequire: "連絡方法は少なくとも一項を入力してください",
  wrongTelFormat: "電話番号を正しく入力してください",
  wrongMailFormat: "メールアドレスを正しく入力してください",
  wrongCorpNumberFormat: "法人番号を正しく入力してください",
  login: "サインイン",
  unknownError: "不明なエラーが発生しました",
  loginFaileds: {
    401: "アカウントまたはパスワードが正しくない"
  },
  registerFaileds: {
    400: "キャプチャが正しくない",
    409: "アカウントはすでに使用されてした"
  },
  notLoginMessage: "アカウント情報が見つかりません、サインインページに戻ります",
  shopManage: "店舗管理",
  menuManage: "メニュー管理",
  accountSettings: "アカウント設定",
  changePassword: "パスワード変更",
  logout: "ログアウト",
  logoutConfirm: "アカウントをログアウトする？",
  oldPassword: "パスワード（旧）",
  newPassword: "パスワード（新）",
  changePasswordSuccess: "パスワードは更新されました",
  changePasswordFaileds: {
    401: "パスワード（旧）が正しくない"
  },
  accountInfo: "個人情報",
  edit: "編集",
  update: "更新",
  cancel: "キャンセル",
  updateAccountSuccess: "個人情報は更新されました",
  noMenuExist: "まだメニューがいません",
  menu: "メニュー",
  newMenuName: "新規メニュー名",
  create: "新規",
  newItem: "新規アイテム",
  updateMenuSuccess: "メニューは更新されました",
  deleteMenuConfirm: "確かにメニューを削除ほしいですか？",
  deleteMenuSuccess: "メニューは削除されました",
  rename: "リネーム",
  delete: "削除",
  price(value) {
    return value + "円"
  },
  priceUnitName: "円",
  priceLabel: "価格",
  editItem: "アイテム編集",
  itemName: "アイテム名称",
  itemDesc: "アイテム説明",
  image: "写真",
  fileSizeLimit(sizeMb) {
    return `${sizeMb}MB以内のファイルを選択してください`
  },
  save: "保存",
  cancelEditItemConfirm: "変更の内容はまだ保存されていません",
  confirmCancelEdit: "変更を廃棄",
  returnToEdit: "編集を続く",
  deleteItemConfirm: "確かにアイテムを削除ほしいですか？",
  itemOptionCategory: "サブメニュー",
  createItemOptionCategory: "サブメニューを追加",
  editItemOptionCategory: "サブメニュー編集",
  itemOptionCategoryName: "名称",
  deleteItemOptionCategoryConfirm: "確かにサブメニューを削除ほしいですか？",
  multipleSelect: "複数選択",
  itemOption: "オプション",
  createItemOption: "追加",
  defaultSelected: "初期選択",
  inputItemOptionHint: "選択肢を入力してください",
  itemOptionCategoryNameRequired: "サブメニュー名称を入力してください",
  itemOptionNameRequired: "選択肢を全て入力したように検査してください",
  deleteItemOptionConfirm: "確かに選択肢を削除ほしいですか？",
  newShop: "新規店舗",
  corpNumber: "法人番号",
  shopInactive: "準備中",
  shopExamine: "審査中",
  shopActive: "使用可能",
  seatAmount(value) {
    return `（${value}席）`
  },
  editShop: "店舗編集",
  deleteShopConfirm: "確かに店舗を削除ほしいですか？",
  deleteShopSuccess: "店舗は削除されました",
  updateShopSuccess: "店舗は更新されました",
  shopName: "店舗名称",
  saveShopFaileds: {
    409: "店舗名称はすでに使用されてした"
  },
  shopCannotEditInExamine: "審査中の店舗は編集できません",
  complete: "完了",
  shopApply: "実装を申込",
  shopApplyConfirm: "審査中の店舗は編集できません、この設置で申込ますか？",
  seat: "座席",
  addSeat: "座席を追加",
  duplicateSeatNameMessage: "座席名は重複しました",
  menuNotFoundMessage: "メニューが見つかりません、お店に問い合わせてください",
  selectAll: "全て",
  qrCode: "QRコード",
  print: "印刷",
  itemImageError: "写真が読み込めません",
  doOrder: "注文する",
  addToCart: "カートに追加",
  modifyCartItem: "変更する",
  itemOptionSeparator: "、",
  doOrderConfirm: "カートの内容を注文しますか？",
  confirm: "確定",
  orderNoticeSessionIsClosed: "接続は切断されました。ページをリロード、または別のアカウントで再試行してください",
  refresh: "リロード",
  loginToOtherAccount: "別のアカウントをサインイン",
  acceptOrder: "注文受付",
  sendOrderFailed: "発注失敗しました。お店に問い合わせてください",
  sendOrderSuccess: "発注成功",
  batchOperation: "一括処理",
  orderHistory: "注文履歴",
  settings: "設定",
  orderIncomingMessage: "注文が入りました",
  cartItemCount(count) {
    return `（${count}点）`
  },
  orderCompleted: "処理済み",
  updateOrderSuccess: "注文状態は更新されました",
  autoPrintTicket: "自動でチケットを印刷",
  autoClearHistory: "注文履歴を自動削除",
  orderHistoryRetainDays: "保留時間（日）",
  createOrderTime: "注文時間",
  updateOrderTime: "更新時間",
  orderStatus: {
    canceled: "取消済み",
    completed: "処理済み"
  },
  printerNotFoundMessage: "プリンター設置が見つかりません、ジャンプソフトに問い合わせてください",
  printerTicket: "チケットを印刷"
}