import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory("/web"),
  routes: [{
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },{
    path: "/menu/:shopName/:seatName",
    name: "Menu",
    component: () => import("@/views/Menu.vue")
  },{
    path: "/qr/:shopName",
    name: "QR",
    component:() => import("@/views/QR.vue")
  },{
    path: "/order-notice/:shopName",
    name: "OrderNotice",
    component:() => import("@/views/OrderNotice.vue")
  },{
    path: "/manage",
    name: "Manage",
    component:() => import("@/views/Manage.vue")
  }]
})

export default router
