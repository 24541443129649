import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/ja'
import 'dayjs/locale/ja'
import router from './router'

import './element-variables.scss'

import lang from "./languages/ja-JP.js" 

createApp(App)
  .use(ElementPlus, { locale })
  .use(router)
  .use({
    install(app) {
      app.config.globalProperties.$lang = lang
    }
  })
  .mount('#app')